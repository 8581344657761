<template>
  <component
    :is="componentName"
    :class="[$style.section, $style[type], { [$style.disabled]: isDisabled }]"
    :to="to"
    :href="href"
    :target="href ? '_blank' : ''"
  >
    <div :class="[$style.container, { [$style.disabled]: isDisabled }]">
      <div :class="[className]">
        <slot />
      </div>

      <svg
        :class="[$style.icon, $style[size]]"
        viewBox="0 0 9 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- eslint-disable -->
        <path
          d="M0.146484 16.1465C-0.0488281 16.3418 -0.0488281 16.6582 0.146484 16.8535C0.244141 16.9512 0.37207 17 0.5 17C0.62793 17 0.755859 16.9512 0.853516 16.8535L8.85352 8.85352C8.95117 8.75586 9 8.62793 9 8.5C9 8.37207 8.95117 8.24414 8.85352 8.14648L0.853516 0.146484C0.658203 -0.0488281 0.341797 -0.0488281 0.146484 0.146484C-0.0488281 0.341797 -0.0488281 0.658203 0.146484 0.853516L7.79297 8.5L0.146484 16.1465Z"
        />
        <!-- eslint-enable -->
      </svg>
    </div>
  </component>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VLink',
  props: {
    to: String,
    href: String,
    className: String,
    type: {
      default: 'shadow',
      validator: (v) => ['default', 'shadow'].includes(v),
      type: String,
    },
    size: {
      default: 'xl',
      validator: (v) => ['sm', 'md', 'lg', 'xl'].includes(v),
      type: String,
    },
    isDisabled: Boolean,
  },
  computed: {
    componentName() {
      if (this.isDisabled) {
        return 'div'
      }
      return this.href ? 'a' : 'router-link'
    },
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  padding: 15px 18px;
  color: $secondary;
  cursor: pointer;
  transition: opacity $transition;

  &:hover {
    opacity: 0.6;
  }

  &.shadow {
    box-shadow: inset 0 -1px 0 $secondary-light;
  }

  &.disabled {
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 9px;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}

.icon {
  align-self: center;
  fill: $secondary;

  &.sm {
    width: 3px;
    height: 6px;
  }

  &.md {
    width: 5px;
    height: 8px;
  }

  &.lg {
    width: 5.5px;
    height: 10px;
  }

  &.xl {
    width: 6px;
    height: 12px;
  }
}
</style>
