<template>
  <div :class="$style.section">
    <v-link
      v-for="({ to, name, disabled }, index) of links"
      :key="`settings-links-${index}`"
      :to="to"
      :is-disabled="disabled"
    >
      <div :class="$style.wrapper">
        <h2 :class="$style.title">{{ $t(`settings.${name}.title`) }}</h2>
        <p :class="$style.description">{{ $t(`settings.${name}.description`) }}</p>
      </div>
    </v-link>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VLink from '@elements/VLink.vue'

export default Vue.extend({
  name: 'Settings',
  components: {
    VLink,
  },
  computed: {
    ...mapGetters('App', ['isMyAppGPT']),
    links() {
      const links = [
        {
          to: '/',
          name: 'security',
          disabled: true,
        },
        {
          to: 'client-edit',
          name: 'account',
        },
        {
          to: '',
          name: 'privacyAndSafety',
          disabled: true,
        },
        {
          to: 'notifications',
          name: 'notifications',
        },
      ]
      return this.isMyAppGPT
        ? links.filter((link) => link.name !== 'security' && link.name !== 'privacyAndSafety')
        : links
    },
  },
  created() {
    this.setPageTitle({ name: 'aside.settings' })
  },
  methods: {
    ...mapMutations('Page', ['setPageTitle']),
  },
})
</script>
<style lang="scss" module>
.section {
  width: 100%;
  height: 100%;
}

.wrapper {
  display: grid;
  row-gap: 9px;
}

.title {
  color: $secondary;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
}

.description {
  color: $secondary;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  opacity: 0.3;
}
</style>
