// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Settings_section_1IuQ9{width:100%;height:100%}.Settings_wrapper_3Hukf{display:grid;row-gap:9px}.Settings_title_1Eg2X{font-weight:500;font-size:15px;line-height:15px}.Settings_description_31MUE,.Settings_title_1Eg2X{color:var(--supster-secondary)}.Settings_description_31MUE{font-weight:400;font-size:12px;line-height:12px;opacity:.3}", ""]);
// Exports
exports.locals = {
	"section": "Settings_section_1IuQ9",
	"wrapper": "Settings_wrapper_3Hukf",
	"title": "Settings_title_1Eg2X",
	"description": "Settings_description_31MUE"
};
module.exports = exports;
